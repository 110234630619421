import { createApp } from 'vue';
import App from './App.vue';
import 'amfe-flexible';
import { Button, Icon, Swipe, SwipeItem, Field, Switch, IndexBar, IndexAnchor, Area, Popup,DropdownMenu, DropdownItem,Loading,Uploader   } from 'vant';
import router from './router/index';
import './common/css/base.less';
import infiniteScroll from 'vue3-infinite-scroll-good'
const app = createApp(App);

app.use(infiniteScroll)
.use(Button)
.use(Icon)
.use(Swipe)
.use(SwipeItem)
.use(Field)
.use(Switch)
.use(IndexBar)
.use(IndexAnchor)
.use(Area)
.use(Popup)
.use(DropdownMenu)
.use(DropdownItem)
.use(Loading)
.use(Uploader)
;

app.use(router);
app.mount('#app');


// 加入百度统计
router.beforeEach((to, from, next) => {
    if (to.path) {
      if (window._hmt) {
        window._hmt.push(['_trackPageview', '/#' + to.fullPath])
      }
    }
    next()
  })
