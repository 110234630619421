import { createRouter, createWebHashHistory } from 'vue-router';

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/home',
      component: () => import('../pages/myHome/index'),
    },
    // 会员服务
    {
      path: '/member',
      component: () => import('../pages/member/index'),
    },

    // 保障业务
    {
      path: '/',
      component: () => import('../pages/guarantee/index'),
    },
	// 保障业务
	{
	  path: '/guarantee',
	  component: () => import('../pages/guarantee/index'),
	},
    // 保障业务
    {
      path: '/guarantee/msg',
      component: () => import('../pages/guarantee/msg'),
    },
    // 会员新闻列表
    {
      path: '/member/newsList',
      component: () => import('../pages/member/list'),
    },
    // 视频号列表列表
    {
      path: '/member/videoList',
      component: () => import('../pages/member/videoList'),
    },
    // 直播页
    {
      path: '/member/live',
      component: () => import('../pages/member/live'),
    },
    // 新闻详情
    {
      path: '/news/details',
      component: () => import('../pages/member/details'),
    },
    // 会员活动列表
    {
      path: '/member/activity',
      component: () => import('../pages/member/activity'),
    },
  ],
});

export default router;
