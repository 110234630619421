<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
.van-image-preview__swipe-item{
  .van-image__img{
    width: 100vw;
  }
}
</style>
